import React from 'react';
import {
  QuestionnaireScore,
  QuestionScore,
  QuestionScoreColumnMeta
} from '../../api/saq/types';
import { TableBody } from '@mui/material';
import { QuestionRow } from './QuestionRow';
import { useUpdateQuestionScores } from '../../api/saq/hooks/mutations';
import { Spinner } from '../../atoms/spinner/Spinner';

export const ScoresBody = <T extends QuestionScore>({
  questionnaireScore,
  columnsMeta
}: {
  questionnaireScore: QuestionnaireScore<T>;
  columnsMeta: QuestionScoreColumnMeta<T>[];
}) => {
  const { isLoading: isUpdateLoading } = useUpdateQuestionScores();

  return (
    <TableBody>
      {/* todo: doesn't work as expected. Table doesn't show new values for scores after saving them*/}
      {isUpdateLoading ? (
        <Spinner />
      ) : (
        questionnaireScore.questions?.map((questionScore: T) => (
          <QuestionRow
            questionScore={questionScore}
            columnsMeta={columnsMeta}
            questionnaireScore={questionnaireScore}
          />
        ))
      )}
    </TableBody>
  );
};
