const checkEnvVar = (envVar: any) => {
  if (!process.env[envVar]) {
    throw new Error(
      `Unable to start application, check env variable ${envVar} is set`
    );
  }
};

export class EnvConfig {
  authorisationServiceUrl: string;
  userServiceBaseUrl: string;
  gravitteeApplicationId: string;
  questionnaireBaseUrl: string;
  scoringBaseUrl: string;
  questionnaireWorkplaceEtlBaseUrl: string;

  constructor() {
    this.authorisationServiceUrl =
      process.env.REACT_APP_AUTHORISATION_SERVICE_URL || '';
    this.userServiceBaseUrl = process.env.REACT_APP_USER_SERVICE_BASE_URL || '';
    this.gravitteeApplicationId =
      process.env.REACT_APP_GRAVITEE_AM_APPICATION_ID || '';
    this.questionnaireBaseUrl =
      process.env.REACT_APP_QUESTIONNAIRE_SERVICE_BASE_URL || '';
    this.scoringBaseUrl = process.env.REACT_APP_SCORING_SERVICE_BASE_URL || '';
    this.questionnaireWorkplaceEtlBaseUrl =
      process.env.REACT_APP_QUESTIONNAIRE_WORKPLACE_ETL_SERVICE_URL || '';

    checkEnvVar('REACT_APP_AUTHORISATION_SERVICE_URL');
    checkEnvVar('REACT_APP_USER_SERVICE_BASE_URL');
    checkEnvVar('REACT_APP_GRAVITEE_AM_APPICATION_ID');
    checkEnvVar('REACT_APP_QUESTIONNAIRE_SERVICE_BASE_URL');
    checkEnvVar('REACT_APP_SCORING_SERVICE_BASE_URL');
  }
}

const environment: EnvConfig = new EnvConfig();

export default environment;
