import {useQuery} from "react-query";
import {SiteQuestionnaireStatusResult} from "api/saq/types";
import {SaqIdentifiers, SaqUrls} from "api/saq/resourcesUrl";
import {questionnaireService} from "api/services";

const fetchSiteQuestionnaireStatus = ({
  queryKey
}: {
    queryKey: string[];
}): Promise<SiteQuestionnaireStatusResult> => {
    const [, siteCode] = queryKey;
    return questionnaireService.get(SaqUrls.siteQuestionnaireStatus(siteCode));
};

export const useSiteQuestionnaireStatus = (siteCode: string) =>
    useQuery<
        SiteQuestionnaireStatusResult,
        Error,
        SiteQuestionnaireStatusResult,
        string[]
        >(
        [SaqIdentifiers.siteQuestionnaireStatus, siteCode],
        fetchSiteQuestionnaireStatus,
        { enabled: !!siteCode }
    );
