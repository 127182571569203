import { useManagementControlsRisk } from 'api/saq/hooks/useManagementControlsRisk/useManagementControlsRisk';
import {
  ManagementCtrlQuestionScore,
  QuestionnaireScore,
  QuestionScoreColumnMeta,
  Section
} from 'api/saq/types';
import { MCSectionContainer } from 'screens/saq/SaqRisksTable.styles';
import { useQuestionnaire } from 'api/saq/hooks/queries';
import { Spinner } from 'atoms/spinner/Spinner';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import React from 'react';
import { Layout } from '../../../layout/Layout';
import { commonSaqColumnNamesArray } from '../consts/commonSaqColumnNames';
import { ScoresTable } from '../../../components/EditableScoresTable/ScoresTable';

const managementControlsColumns = [
  { name: 'Sub-section', property: 'subsectionName' },
  { name: 'Question', property: 'question' }
] as QuestionScoreColumnMeta<ManagementCtrlQuestionScore>[];

export const ManagementControls = () => {
  const { questionnaireType } = useQuestionnaireContext();
  const {
    data: questionnaire,
    isLoading: isLoadingQuestionnaire
  } = useQuestionnaire(questionnaireType);
  const { questions, scoreResponse, isLoading } = useManagementControlsRisk(
    questionnaireType
  );

  const getSectionInfo = (section: Section) =>
    questions.filter((row: ManagementCtrlQuestionScore) =>
      section.children
        .map((subsection: Section) => subsection.id)
        .includes(row.subsectionId)
    );
  const managementControlColumnNames = managementControlsColumns
    .map((col) => col.name)
    .concat(commonSaqColumnNamesArray);

  if (isLoadingQuestionnaire || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <QuestionnaireTypeSelector />
      <Layout title="Risk: Management Controls - question scores">
        {questionnaire &&
          questionnaire.sections.map((section: Section) => (
            <MCSectionContainer key={`${section.name} section MC Scores`}>
              <ScoresTable
                title={section.name}
                questionnaireScore={
                  {
                    questions: getSectionInfo(section),
                    scoreResponse: scoreResponse,
                    isLoading
                  } as QuestionnaireScore<ManagementCtrlQuestionScore>
                }
                headerNames={managementControlColumnNames}
                columnMetas={managementControlsColumns}
              />
            </MCSectionContainer>
          ))}
      </Layout>
    </>
  );
};
