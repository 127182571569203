import { useSiteCharacteristic } from 'api/saq/hooks/useSiteCharacteristic/useSiteCharacteristic';
import { QuestionScoreColumnMeta, QuestionnaireScore, SiteCharQuestionScore } from 'api/saq/types';
import React from 'react';
import { useQuestionnaireContext } from '../context/QuestionnaireProvider';
import { QuestionnaireTypeSelector } from '../../components/QuestionnaireTypeSelector';
import { commonSaqColumnNamesArray } from '../consts/commonSaqColumnNames';
import { ScoresTable } from '../../../components/EditableScoresTable/ScoresTable';

const siteCharacteristicColumns = [
  { name: 'Pillar', property: 'pillar' },
  { name: 'Question', property: 'question' },
  { name: 'SAQ vs Worker info table', property: 'isSaq' }
] as QuestionScoreColumnMeta<SiteCharQuestionScore>[];

export const SiteCharacteristic = () => {
  const { questionnaireType } = useQuestionnaireContext();
  const { questions, scoreResponse, isLoading } = useSiteCharacteristic(
    questionnaireType
  );
  const scColumnNames = siteCharacteristicColumns
    .map((col) => col.name)
    .concat(commonSaqColumnNamesArray);

  return (
    <>
      <QuestionnaireTypeSelector />
      <ScoresTable
        title="Risk: Site Characteristics - question scores"
        questionnaireScore={
          { questions: questions, scoreResponse: scoreResponse, isLoading } as QuestionnaireScore<SiteCharQuestionScore>
        }
        headerNames={scColumnNames}
        columnMetas={siteCharacteristicColumns}
      />
    </>
  );
};
