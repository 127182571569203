import { Box, Paper, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from 'atoms/spinner/Spinner';

type LayoutProps = {
  title?: string;
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[];
};

export const Layout = ({ title, isLoading = false, children }: LayoutProps) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Paper className={styles.content}>
        {title && (
          <Toolbar className={styles.toolbar}>
            <Typography variant="h6" id="tableTitle" component="div">
              {title}
            </Typography>
          </Toolbar>
        )}
        {isLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          children
        )}
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    width: '100%',

    mb: 2
  },
  spinner: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center'
  },
  toolbar: { display: 'flex', justifyContent: 'space-between' }
}));
