type Response<T> = {
  results: T[];
  links: {};
  totalRecords: number;
};

export type QuestionPossibleAnswer = {
  id: string;
  label: string;
};

export type CalculatedQuestionPossibleAnswer = {
  key: string;
  label: string;
};

export type QuestionType =
  | 'TEXT'
  | 'CHOICE'
  | 'WRAPPER'
  | 'MULTI_CHOICE'
  | 'FILE';

export type Tag = {
  category: Category;
  subCategory: SubCategory;
};

export type Category = 'PILLAR' | 'RISK';

export type SubCategory =
  | 'LABOUR_STANDARDS_AND_RIGHTS'
  | 'SITE_CHARACTERISTICS'
  | 'MANAGEMENT_CONTROLS'
  | 'HEALTH_AND_SAFETY';

export type QuestionResult = {
  id: string;
  subsectionId: string;
  label: string;
  questionType: QuestionType;
  possibleAnswers?: QuestionPossibleAnswer[];
  tags?: Tag[];
};

export type CalculatedQuestionResult = {
  id: string;
  subsectionId: string;
  label: string;
  questionType: QuestionType;
  options?: CalculatedQuestionPossibleAnswer[];
  tags?: Tag[];
};

export type QuestionResponse = Response<QuestionResult>;

export type CalculatedQuestionResponse = Response<CalculatedQuestionResult>;

export type ScoreResponse = Response<ScoreResult>;

export type ScoreResult = {
  id: string;
  questionnaire: QuestionnaireType;
  questionCode: string;
  scores: Scores;
  createdTime: Date;
  updatedTime: Date;
};

export type QuestionnaireType = 'GP';

export type Scores = {
  possibleAnswers?: ScorePossibleAnswer[];
  maxScore: number;
  type: ScoreType;
  basicAnswer?: BasicAnswer;
  bands?: Band[];
};

export type Band = {
  from?: number;
  to?: number;
  scores: CompanySizeScore;
};

export type CompanySizeScore = {
  LARGE?: number;
  MEDIUM?: number;
  MICRO?: number;
  SMALL?: number;
};

export type BasicAnswer = {
  answered: CompanySizeScore;
  unanswered: CompanySizeScore;
};

export type ScorePossibleAnswer = {
  code: string;
  scores: CompanySizeScore;
};

export type ScoreType = 'choice' | 'basic' | 'banded';

export interface QuestionnaireScore<T extends QuestionScore> {
  questions: T[];
  isLoading: boolean;
  scoreResponse?: ScoreResponse;
}

export interface QuestionScore {
  question: string;
  questionCode: string;
  answerScores?: AnswerScore[];
  maxScore?: number;
}

export interface SiteCharQuestionScore extends QuestionScore {
  pillar?: string;
  isSaq: boolean;
}

export type AnswerScore = {
  label: string;
  code?: string;
  companySizeScore?: CompanySizeScore;
};

export interface ManagementCtrlQuestionScore extends QuestionScore {
  subsectionId: string;
  subsectionName: string;
}

export type Section = {
  children: Section[];
  code: string;
  description: string;
  id: string;
  name: string;
};

export type ScoreUpdate = {
  scoreId: string;
  scores: Scores;
};

export type QuestionScoreColumnMeta<T extends QuestionScore> = {
  name: string;
  property: keyof T;
};

type SiteQuestionnaire = {
  id: string;
  questionnaireId: string;
};

export type SiteQuestionnaireStatusResult = {
  siteQuestionnaire: SiteQuestionnaire;
};

export enum AnswerDataTypeName {
  STRING = 'string',
  STRING_ARRAY = 'stringarray',
  CHOICE = 'choice',
  DATAGRID = 'datagrid',
  MULTICHOICE = 'multichoice',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  FILE = 'file'
}

export type AnswerData = {
  dataType: AnswerDataTypeName;
  value: unknown;
};

export type MultiChoiceAnswerValue = {
  answerCode: string;
  value: string;
};

export type FileAnswerValue = {
  fileName?: string;
  fileLink?: string;
  fileId?: string;
};

export type FileViewAnswerValue = {
  fileName?: string;
  fileLink?: string;
  fileDownloadLink?: string;
};

export type AnswerItem = {
  questionCode: string;
  answerData: AnswerData;
};

export type Answers = {
  id: string;
  siteQuestionnaireId: string;
  lastModified?: string;
  sectionId: string;
  answers: AnswerItem[];
};

export type AnswerResponse = {
  links: Record<string, unknown>;
  results: Answers[];
  totalRecords: number;
};

export type CalculatedRiskAnswersResult = {
  siteCode: string;
  answers: AnswerItem[];
};

export type WorkplaceRiskScoresResult = {
  siteCode: string;
  scores: WorkplaceRiskScores;
  lastModifiedAt: Date;
};

export type WorkplaceRiskScores = {
  siteCharacteristicsRisk: SiteCharacteristicsRisk;
  managementControlsRisk: ManagementControlsRisk;
};

export type SiteCharacteristicsRisk = {
  score: number;
  pillars: Record<string, number>;
  questionScores: Record<string, number>;
};

export type ManagementControlsRisk = {
  score: number;
  sectionScores: Record<string, number>;
  questionScores: Record<string, number>;
};

export type RiskData = {
  label: string;
  score: number;
};

export type QuestionRiskData = RiskData & {
  tags: string[];
  answer: string[] | FileAnswerValue[];
};

export type SiteCharacteristicsRiskData = {
  totalScore: number;
  pillars: RiskData[];
  questionScores: QuestionRiskData[];
};

export type ManagementControlsRiskData = {
  totalScore: number;
  sectionScores: RiskData[];
  questionScores: QuestionRiskData[];
};
