import {
  AnswerScore,
  QuestionnaireScore,
  QuestionScore,
  QuestionScoreColumnMeta,
  Scores,
  ScoreUpdate
} from '../../api/saq/types';
import React, { Fragment, useState } from 'react';
import { changeScores } from '../../api/saq/utils';
import { SaqIdentifiers } from '../../api/saq/resourcesUrl';
import { useUpdateQuestionScores } from '../../api/saq/hooks/mutations';
import { useQueryClient } from 'react-query';
import { EditControls } from './EditControls';
import { EditCell } from './EditCell';
import { AnswerScoreCells } from './AnswerScoreCells';
import { TableCell } from '@mui/material';
import { StyledTableRow } from '../../screens/saq/SaqRisksTable.styles';

type RowState = {
  questionCode?: string;
  answerScores: AnswerScore[];
  maxScore: number;
};

type QuestionRowProps<T extends QuestionScore> = {
  questionScore: T;
  questionnaireScore: QuestionnaireScore<T>;
  columnsMeta: QuestionScoreColumnMeta<T>[];
};

export const QuestionRow = <T extends QuestionScore>({
  questionScore,
  columnsMeta,
  questionnaireScore
}: QuestionRowProps<T>) => {
  const initRowState: RowState = {
    questionCode: '',
    answerScores: [],
    maxScore: -1
  };

  const { mutate: updateScores } = useUpdateQuestionScores();
  const [rowState, setRowState] = useState(initRowState);
  const queryClient = useQueryClient();

  const showEdit = (questionCode: string) => {
    setRowState({
      questionCode,
      answerScores: questionScore.answerScores || [],
      maxScore: questionScore.maxScore || 0
    });
  };

  const onHideEdit = () => {
    setRowState(initRowState);
  };

  const onAnswerChange = (answerScore: AnswerScore) => {
    setRowState({
      ...rowState,
      answerScores: [
        ...rowState.answerScores.filter(
          (answerAndScores) => answerAndScores.label !== answerScore.label
        ),
        answerScore
      ]
    });
  };

  const handleMaxScoreChange = (value: string) => {
    setRowState({
      ...rowState,
      maxScore: Number.parseInt(value, 10)
    });
  };

  const isMaxScoreInvalid =
    (rowState.answerScores
      ?.flatMap((answerScore) => answerScore.companySizeScore)
      .flatMap((companySizeScore) =>
        Object.values(companySizeScore || {})
      ) as number[]).some((score) => rowState.maxScore < score) ||
    rowState.maxScore > 100;

  const onSaveScores = async () => {
    const existingScoreResult = questionnaireScore.scoreResponse?.results?.find(
      (scoreResult) => rowState.questionCode === scoreResult.questionCode
    );
    const changedScores = changeScores(
      existingScoreResult?.scores || ({} as Scores),
      rowState.answerScores,
      rowState.maxScore
    );
    updateScores(
      {
        scoreId: existingScoreResult?.id,
        scores: changedScores
      } as ScoreUpdate,
      // todo: move to query
      {
        onSuccess: () => {
          const newAllScores = {
            ...questionnaireScore.scoreResponse,
            results: questionnaireScore.scoreResponse?.results.map((score) =>
              rowState.questionCode === score.questionCode
                ? { ...score, scores: changedScores }
                : score
            )
          };
          queryClient.setQueryData([SaqIdentifiers.score], newAllScores);
          onHideEdit();
        },
        onError: (error) => {
          console.error(error.message);
          alert('Unable to update scores');
        }
      }
    );
  };

  const getRiskCategory = (questionScore: T, property: keyof T) => {
    if (property === 'isSaq' && questionScore[property]) return 'SAQ';
    else if (property === 'isSaq') return 'Workers Info';
    else return questionScore[property];
  };

  const answerSize = questionScore.answerScores
    ? questionScore.answerScores.length
    : 0;

  return (
    <Fragment key={`question-${questionScore.questionCode}`}>
      {/*
      There is no way to view multiple rows grouped for MUI table.
      As a workaround we need to view all the answer scores data as rows and expand question data cells to fit answer rows.
      */}
      {questionScore.answerScores?.map((answerScore, index) => (
        <StyledTableRow
          showDelimiter={index === 0}
          key={`question-${questionScore.questionCode}-${index}`}
        >
          {/*question metadata*/}
          {index === 0 &&
            columnsMeta.map((column) => (
              <TableCell
                align="center"
                size="small"
                scope="row"
                rowSpan={answerSize}
                key={`question-${questionScore.questionCode}-${column.property as string}`}
              >
                {getRiskCategory(questionScore, column.property)}
              </TableCell>
            ))}

          <AnswerScoreCells
            questionCode={questionScore.questionCode}
            answerScore={answerScore}
            isEditable={questionScore.questionCode === rowState.questionCode}
            onShow={showEdit}
            onChange={onAnswerChange}
          />

          {index === 0 && (
            <>
              {/*max score editable cell*/}
              <EditCell
                questionCode={questionScore.questionCode}
                initValue={questionScore.maxScore}
                isEditable={
                  questionScore.questionCode === rowState.questionCode
                }
                onShow={showEdit}
                onChange={handleMaxScoreChange}
                isError={isMaxScoreInvalid}
                rowSpan={answerSize}
              />
              <EditControls
                isEditable={
                  questionScore.questionCode === rowState.questionCode
                }
                isDisabled={isMaxScoreInvalid}
                onSaveScores={onSaveScores}
                onHideEdit={onHideEdit}
                rowSpan={answerSize}
              />
            </>
          )}
        </StyledTableRow>
      ))}
    </Fragment>
  );
};
