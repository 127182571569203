import { IconButton, TableCell } from '@mui/material';
import { Check, Close } from '@material-ui/icons';
import React, { MouseEventHandler } from 'react';

type EditControlsProps = {
  isEditable: boolean;
  isDisabled: boolean;
  onSaveScores: MouseEventHandler;
  onHideEdit: MouseEventHandler;
  rowSpan: number;
};

export const EditControls = ({
  isEditable,
  isDisabled,
  onSaveScores,
  onHideEdit,
  rowSpan
}: EditControlsProps) => {
  return isEditable ? (
    <TableCell
      align="center"
      size="small"
      scope="row"
      key="editControls"
      rowSpan={rowSpan}
    >
      <IconButton size="small" disabled={isDisabled} onClick={onSaveScores}>
        <Check />
      </IconButton>
      <IconButton size="small" onClick={onHideEdit}>
        <Close />
      </IconButton>
    </TableCell>
  ) : (
    <TableCell
      align="center"
      size="small"
      scope="row"
      key="editControls"
      rowSpan={rowSpan}
      width="65px"
    />
  );
};
