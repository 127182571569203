import { EditBox, StyledCell } from '../../screens/saq/SaqRisksTable.styles';
import { InputBaseComponentProps } from '@mui/material';
import React from 'react';
import { CompanySizeScore } from '../../api/saq/types';
import { Typography } from '@material-ui/core';

const numberInputProps = {
  type: 'number',
  inputMode: 'numeric',
  pattern: '[0-9]*',
  min: 0,
  max: 100
} as InputBaseComponentProps;

type CellProps = {
  questionCode: string;
  initValue?: number;
  companySizeLabel?: keyof CompanySizeScore;
  isEditable: boolean;
  isError?: boolean;
  onShow: Function;
  onChange: Function;
  rowSpan?: number;
};

export const EditCell = ({
  questionCode,
  companySizeLabel,
  initValue,
  isEditable,
  isError = false,
  onShow,
  onChange,
  rowSpan
}: CellProps) => {
  return isEditable ? (
    <StyledCell
      align="center"
      size="small"
      scope="row"
      key={`score-${companySizeLabel}`}
      rowSpan={rowSpan}
    >
      <EditBox
        placeholder="-"
        inputProps={numberInputProps}
        defaultValue={initValue}
        error={isError}
        onChange={(event) => onChange(event.target.value, companySizeLabel)}
      />
    </StyledCell>
  ) : (
    <StyledCell
      align="center"
      size="small"
      scope="row"
      onClick={() => onShow(questionCode)}
      key={`score-${companySizeLabel}`}
      rowSpan={rowSpan}
    >
      <Typography>{initValue || initValue === 0 ? initValue : '-'}</Typography>
    </StyledCell>
  );
};
