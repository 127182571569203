import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  StyledTableRow,
  TableCellWithBorder,
  TableHeadCell
} from '../../screens/saq/SaqRisksTable.styles';
import { Fragment } from 'react';
import { Layout } from '../../layout/Layout';
import { RiskData } from '../../api/saq/types';

interface FeatureTableProps {
  title: string;
  data: RiskData[];
}

export const WorkplaceRiskTable: React.FC<FeatureTableProps> = ({
  title,
  data
}) => {
  return (
    <Layout title={title} isLoading={false}>
      <Table>
        <TableHead>
          <TableRow key={`heading`}>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Score</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((row: RiskData) => (
              <Fragment key={`row-${row.label}`}>
                <StyledTableRow
                  key={`score-${row.label}`}
                  showDelimiter={true}
                >
                  <TableCellWithBorder scope="row" size="small" key="label">
                    {row.label}
                  </TableCellWithBorder>
                  <TableCell scope="row" size="small" key="value" width={200}>
                    {row.score}
                  </TableCell>
                </StyledTableRow>
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </Layout>
  );
};
