import { AnswerScore, CompanySizeScore } from '../../api/saq/types';
import { TableCell } from '@mui/material';
import React from 'react';
import { EditCell } from './EditCell';

type ScoreRowProps = {
  questionCode: string;
  answerScore: AnswerScore;
  onShow: Function;
  onChange: Function;
  isEditable: boolean;
};

export const AnswerScoreCells = ({
  questionCode,
  answerScore,
  onShow,
  onChange,
  isEditable
}: ScoreRowProps) => {
  const companySizes = [
    'MICRO',
    'SMALL',
    'MEDIUM',
    'LARGE'
  ] as (keyof CompanySizeScore)[];

  const onScoreChange = (value: string, companySize: string) => {
    // todo: what the hex is that? needs refactor as this code is misleading
    // get rest size properties if we are deleting the value
    const { [companySize as keyof CompanySizeScore]: _, ...rest } =
      answerScore.companySizeScore || {};
    const changedAnswerScore = {
      ...answerScore,
      companySizeScore:
        value === ''
          ? rest
          : {
              ...answerScore.companySizeScore,
              [companySize]: Number.parseInt(value, 10)
            }
    } as AnswerScore;

    onChange(changedAnswerScore);
  };

  return (
    <>
      <TableCell
        size="small"
        scope="row"
        key={`answer-${answerScore.label}`}
      >
        {answerScore.label}
      </TableCell>

      {companySizes.map((companySizeLabel) => (
        <EditCell
          questionCode={questionCode}
          companySizeLabel={companySizeLabel}
          initValue={answerScore.companySizeScore?.[companySizeLabel]}
          isEditable={isEditable}
          onShow={onShow}
          onChange={onScoreChange}
        />
      ))}
    </>
  );
};
