import {
  AnswerDataTypeName,
  AnswerItem,
  Answers,
  Band,
  CalculatedRiskAnswersResult,
  FileAnswerValue,
  FileViewAnswerValue,
  AnswerScore,
  MultiChoiceAnswerValue,
  QuestionPossibleAnswer,
  QuestionResult,
  QuestionRiskData,
  ScorePossibleAnswer,
  ScoreResult,
  Scores,
  SiteCharQuestionScore,
  Tag
} from 'api/saq/types';
import { SaqUrls } from 'api/saq/resourcesUrl';

export const getPillar = (tags: Tag[] | undefined) => {
  const pillar = tags?.find((scTag: Tag) => scTag.category === 'PILLAR')
    ?.subCategory;

  switch (pillar) {
    case 'LABOUR_STANDARDS_AND_RIGHTS':
      return 'LABOUR STANDARDS';
    case 'HEALTH_AND_SAFETY':
      return 'HEALTH AND SAFETY';
    case 'MANAGEMENT_CONTROLS':
      return 'MANAGEMENT CONTROLS';
    case 'SITE_CHARACTERISTICS':
      return 'SITECHARACTERISTICS';
    default:
      return '';
  }
};

export const getBandScoreLabel = (score: Band): string => {
  if (score.from === 0 && score.to === 0) return '0';
  if (score.to) return `${score.from || 0} - ${score.to}`;
  if (score.from === 0 || score.to === 0) return '0';
  return `${score.from || score.to}+`;
};

export const changeScores = (
  scores: Scores,
  newScores: AnswerScore[],
  newMaxScore: number
): Scores => {
  switch (scores.type) {
    case 'choice':
      return {
        ...scores,
        possibleAnswers: scores.possibleAnswers?.map(
          (answerInfo) =>
            ({
              code: answerInfo.code,
              scores: newScores.find(
                (scoreInfo) => scoreInfo.code === answerInfo.code
              )?.companySizeScore
            } as ScorePossibleAnswer)
        ),
        maxScore: newMaxScore
      };
    case 'basic':
      return {
        ...scores,
        basicAnswer: {
          answered:
            newScores.find((scoreInfo) => scoreInfo.label === 'Answered')
              ?.companySizeScore || {},
          unanswered:
            newScores.find((scoreInfo) => scoreInfo.label === 'Unanswered')
              ?.companySizeScore || {}
        },
        maxScore: newMaxScore
      };
    case 'banded':
      return {
        ...scores,
        bands: scores.bands?.map(
          (band) =>
            ({
              from: band.from,
              to: band.to,
              scores: newScores.find(
                (scoreInfo) => scoreInfo.label === getBandScoreLabel(band)
              )?.companySizeScore
            } as Band)
        ),
        maxScore: newMaxScore
      };
    default:
      return scores;
  }
};

const getAnswer = (
  possibleAnswers: QuestionPossibleAnswer[],
  answerResponse: Answers | undefined,
  questionId: string
): string[] | FileViewAnswerValue[] => {
  const answerData = answerResponse?.answers.find(
    (answer) => answer.questionCode === questionId
  )?.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.MULTICHOICE: {
        const value = answerData.value as MultiChoiceAnswerValue[];
        return value.map((answer) => {
          const possibleAnswer = possibleAnswers.find(
            (a) => a.id === answer.answerCode
          );

          return possibleAnswer ? possibleAnswer.label : answer.value;
        });
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        return [value.toString()];
      case AnswerDataTypeName.FILE: {
        const value = answerData.value as FileAnswerValue[];
        return value.map((a) => ({
          fileName: a.fileName ?? '',
          fileLink: a.fileLink ?? '',
          fileDownloadLink: a.fileId
            ? SaqUrls.file(
                answerResponse.siteQuestionnaireId,
                answerResponse.sectionId,
                questionId,
                a.fileId
              )
            : ''
        }));
      }
      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};

export const getQuestionRiskFromRecord = (
  record: Record<string, number> | undefined,
  allQuestions: QuestionResult[],
  allAnswers: Answers[],
  calculatedAnswers: CalculatedRiskAnswersResult | undefined
): QuestionRiskData[] => {
  const riskData = [];

  if (record) {
    for (const key in record) {
      const question = allQuestions?.find((question) => question.id === key);
      const answerResponse = allAnswers?.find(
        (answer: Answers) => answer.sectionId === question?.subsectionId
      );
      let calculatedAnswer: AnswerItem | undefined = undefined;

      if (!answerResponseHasAnswer(answerResponse, key)) {
        calculatedAnswer = calculatedAnswers?.answers.find(
          (answer) => answer.questionCode === key
        );
      }

      riskData.push({
        label: question?.label || key,
        tags: question?.tags ? question.tags.map((tag) => tag.subCategory) : [],
        score: record[key],
        answer: calculatedAnswer
          ? getCalculatedAnswer(
              question?.possibleAnswers ?? [],
              calculatedAnswer
            )
          : getAnswer(question?.possibleAnswers ?? [], answerResponse, key)
      });
    }
  }

  return riskData;
};

const answerResponseHasAnswer = (
  answerResponse: Answers | undefined,
  questionCode: string
): boolean => {
  return !!answerResponse?.answers?.find(
    (answer) => answer.questionCode === questionCode
  );
};

export const sortingScoreQuestions = (
  allQuestions: QuestionResult[],
  scoreQuestions: Record<string, number> | undefined
) => {
  if (scoreQuestions) {
    const sortedScoreQuestions: Record<string, number> = {};
    allQuestions.forEach((question) => {
      for (const key in scoreQuestions) {
        if (question.id === key) {
          sortedScoreQuestions[key] = scoreQuestions[key];
        }
      }
    });
    return sortedScoreQuestions;
  } else {
    return undefined;
  }
};

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const getRawAnswerLabel = (rawLabel: string): string => {
  const suffix = rawLabel.substring(rawLabel.lastIndexOf('.') + 1);
  return suffix.length === 2 && suffix === suffix.toUpperCase()
    ? regionNames.of(suffix) || rawLabel
    : rawLabel;
};

const getBandedScores = (
  questionScore: ScoreResult
): AnswerScore[] | undefined =>
  questionScore.scores?.bands?.map((score) => ({
    label: getBandScoreLabel(score),
    scores: score.scores
  }));

export const getAnswerScores = (
  question?: QuestionResult,
  scoreResult?: ScoreResult
): AnswerScore[] | undefined => {
  if (scoreResult?.scores.type === 'banded') {
    return getBandedScores(scoreResult);
  }
  if (scoreResult?.scores.type === 'choice') {
    return scoreResult?.scores?.possibleAnswers?.map(
      (scoreAnswer: ScorePossibleAnswer) => ({
        label:
          question?.possibleAnswers?.find(
            (questionAnswer: QuestionPossibleAnswer) =>
              scoreAnswer.code === questionAnswer.id
          )?.label || getRawAnswerLabel(scoreAnswer.code),
        code: scoreAnswer.code,
        companySizeScore: scoreAnswer?.scores
      } as AnswerScore)
    ) as AnswerScore[];
  }
  return [
    {
      label: 'Answered',
      companySizeScore: scoreResult?.scores.basicAnswer?.answered
    },
    {
      label: 'Unanswered',
      companySizeScore: scoreResult?.scores.basicAnswer?.unanswered
    }
  ];
};

export const createQuestionsByScores = (scores: ScoreResult[] | undefined) =>
  scores?.map((scoreInfo) => ({
    pillar: 'LABOUR STANDARDS',
    isSaq: !scoreInfo.questionCode.startsWith('employee.'),
    question: scoreInfo.questionCode,
    questionCode: scoreInfo.questionCode,
    maxScore: scoreInfo.scores.maxScore,
    scores: getAnswerScores(undefined, scoreInfo)
  })) || ([] as SiteCharQuestionScore[]);

const getCalculatedAnswer = (
  possibleAnswers: QuestionPossibleAnswer[],
  calculatedAnswer: AnswerItem
): string[] => {
  const answerData = calculatedAnswer.answerData;

  if (answerData) {
    switch (answerData.dataType) {
      case AnswerDataTypeName.STRING: {
        const value = answerData.value as string;
        const possibleAnswer = possibleAnswers.find(
          (answer) => answer.id === value
        );
        return possibleAnswer ? [possibleAnswer.label] : [value];
      }
      case AnswerDataTypeName.NUMBER:
        const value = answerData.value as number;
        return [value.toString()];

      default: {
        return [
          answerData.dataType
            ? `Answer type ${answerData.dataType.toUpperCase()} was not processed`
            : ''
        ];
      }
    }
  }
  return [''];
};
