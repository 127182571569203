import {
  useQuestionnaire,
  useQuestions,
  useScore
} from 'api/saq/hooks/queries';
import { QuestionResult, ScoreResult, Section, Tag } from 'api/saq/types';

export const useQuestionScoresData = (
  riskType: string,
  questionnaireType: string
) => {
  const {
    data: questionnaire,
    isLoading: isQuestionnaireLoading
  } = useQuestionnaire(questionnaireType);
  const { data: scores, isLoading: isScoreLoading } = useScore(
    questionnaire?.id,
    questionnaireType
  );
  const { data: allQuestions, isLoading: isQuestionLoading } = useQuestions(
    questionnaire?.id
  );

  const scoresWithoutQuestions = scores?.results
    ?.filter(
      (score: ScoreResult) =>
        allQuestions?.results.find(
          (question) => question.id === score.questionCode
        ) === undefined
    )
    .filter(
      (score: ScoreResult) => !score.questionCode.startsWith('employee.')
    );

  const riskQuestions = allQuestions?.results?.filter(
    (question: QuestionResult) =>
      question?.tags?.some((tag: Tag) => tag.subCategory === riskType)
  );
  return {
    scores,
    scoresWithoutQuestions,
    subsections: questionnaire?.sections.flatMap(
      (section: Section) => section.children
    ),
    riskQuestions,
    isLoading: isScoreLoading || isQuestionLoading || isQuestionnaireLoading
  };
};
