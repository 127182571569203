import { Table, TableContainer } from '@mui/material';
import {
  QuestionnaireScore,
  QuestionScore,
  QuestionScoreColumnMeta
} from 'api/saq/types';
import { Layout } from 'layout/Layout';
import React from 'react';
import { ScoresHeader } from './ScoresHeader';
import { ScoresBody } from './ScoresBody';

export type ScoresTableProps<T extends QuestionScore> = {
  title: string;
  questionnaireScore: QuestionnaireScore<T>;
  headerNames: string[];
  columnMetas: QuestionScoreColumnMeta<T>[];
};

export const ScoresTable = <T extends QuestionScore>({
  title,
  questionnaireScore,
  headerNames,
  columnMetas
}: ScoresTableProps<T>) => {
  return (
    <Layout title={title} isLoading={questionnaireScore.isLoading}>
      <TableContainer>
        <Table stickyHeader size={'small'}>
          <ScoresHeader title={title} headerNames={headerNames} />
          <ScoresBody
            questionnaireScore={questionnaireScore}
            columnsMeta={columnMetas}
          />
        </Table>
      </TableContainer>
    </Layout>
  );
};
