import React, { createContext, FC, useContext, useMemo, useState } from 'react';

export type QuestionnaireProps = {
  questionnaireType: string;
  setQuestionnaireType: React.Dispatch<React.SetStateAction<string>>;
};

const initialState: string = 'gp';

const QuestionnaireContext = createContext<QuestionnaireProps | null>(null);

export const QuestionnaireProvider: FC = ({ children }) => {
  const [questionnaireType, setQuestionnaireType] = useState(initialState);

  const value = useMemo(
    () => ({
      questionnaireType,
      setQuestionnaireType
    }),
    [questionnaireType]
  );

  return (
    <QuestionnaireContext.Provider value={value as QuestionnaireProps}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export const useQuestionnaireContext = () => {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    throw new Error(
      'useQuestionnaireContext must be used within AdminQuestionnaireProvider'
    );
  }

  return context;
};
