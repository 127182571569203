import { List, ListItem, Link, Button } from '@mui/material';
import { FileViewAnswerValue } from 'api/saq/types';
import { ArrowDownward } from '@material-ui/icons';
import { useDownloadFile } from 'api/saq/hooks/useDownloadFile/useDownloadFile';

type ListAnswersProps = {
  listData: string[] | FileViewAnswerValue[];
};

export const ListAnswers: React.FC<ListAnswersProps> = ({ listData }) => {
  const { download } = useDownloadFile();
  return (
    <List>
      {listData.map((value, index) => {
        if (typeof value === 'string') {
          return <ListItem key={index}>{value}</ListItem>;
        } else if (value.fileLink) {
          return (
            <ListItem key={index}>
              <Link href={value.fileLink} underline="hover" target={'_blank'}>
                {value.fileLink}
              </Link>
            </ListItem>
          );
        } else if (value.fileName) {
          return (
            <ListItem key={index}>
              <Button
                variant="outlined"
                startIcon={<ArrowDownward />}
                onClick={() =>
                  download(value.fileName ?? '', value.fileDownloadLink ?? '')
                }
              >
                {value.fileName}
              </Button>
            </ListItem>
          );
        }

        return null;
      })}
    </List>
  );
};
