import {ScoreUpdate} from "api/saq/types";
import {scoringService} from "api/services";
import {SaqIdentifiers, ScoringUrls} from "api/saq/resourcesUrl";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const updateScores = (payload: ScoreUpdate) =>
    scoringService.put(ScoringUrls.updateScores(payload.scoreId), payload.scores);

export const useUpdateQuestionScores = () =>
    useMutation<ScoreUpdate, AxiosError, ScoreUpdate>(
        SaqIdentifiers.updateScores,
        updateScores
    );
