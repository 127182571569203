import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { TableHeadCell } from 'screens/saq/SaqRisksTable.styles';
import { Fragment } from 'react';
import { Layout } from 'layout/Layout';
import { QuestionRiskData } from 'api/saq/types';
import { ListAnswers } from 'components/ListAnswers/ListAnswers';

type WorkplaceRiskTableProps = {
  title: string;
  data: QuestionRiskData[];
};

export const WorkplaceRiskAnswersTable: React.FC<WorkplaceRiskTableProps> = ({
  title,
  data
}) => (
  <Layout title={title} isLoading={false}>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableHeadCell>Question</TableHeadCell>
          <TableHeadCell>Tags</TableHeadCell>
          <TableHeadCell>Score</TableHeadCell>
          <TableHeadCell>Answers</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row: QuestionRiskData, index: number) => (
          <Fragment key={`row-${index}`}>
            <TableRow>
              <TableCell size="small" width="40%">
                {row.label}
              </TableCell>
              <TableCell size="small" width="20%">
                <ListAnswers listData={row?.tags} />
              </TableCell>
              <TableCell size="small" width="10%">
                {row?.score}
              </TableCell>
              <TableCell size="small" width="30%">
                <ListAnswers listData={row?.answer} />
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </TableBody>
    </Table>
  </Layout>
);
