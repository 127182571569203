import { ChangeEvent, useState } from 'react';
import { useSiteRisksScore } from 'api/saq/hooks/useSiteRisksScore/useSiteRisksScore';
import { WorkplaceRiskAnswersTable } from 'components/WorkplaceRiskAnswersTable/WorkplaceRiskAnswersTable';
import { useStyles } from '../../Features/features';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { TableContainer } from '../SaqRisksTable.styles';

export const WorkplaceRiskAnswers = () => {
  const classes = useStyles();
  const [siteCode, setSiteCode] = useState<string>('');
  const [siteCodeRequest, setSiteCodeRequest] = useState<string>('');
  const {
    managementControlsRisk,
    siteCharacteristicsRisk,
    isLoading,
    isSuccess
  } = useSiteRisksScore('GP', siteCodeRequest, true);

  const onChangeSiteCodeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setSiteCode(event.target.value);

  const onSearchHandler = () => {
    if (!siteCode) return;
    setSiteCodeRequest(siteCode);
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6">Workplace Risk Answers</Typography>
      <Grid className={classes.inputForm}>
        <TextField
          label="Site Code"
          onChange={onChangeSiteCodeHandler}
          value={siteCode}
        />
        <Button
          type="button"
          className={classes.button}
          variant="contained"
          onClick={onSearchHandler}
        >
          Search
        </Button>
      </Grid>
      {!isLoading && isSuccess && (
        <>
          {siteCharacteristicsRisk.questionScores.length !== 0 && (
            <WorkplaceRiskAnswersTable
              title="Site Characteristics Risks Answers"
              data={siteCharacteristicsRisk.questionScores}
            />
          )}
          {managementControlsRisk.questionScores.length !== 0 && (
            <TableContainer>
              <WorkplaceRiskAnswersTable
                title="Management Controls Risk Answers"
                data={managementControlsRisk.questionScores}
              />
            </TableContainer>
          )}
        </>
      )}
      {siteCodeRequest !== '' && !isLoading && !isSuccess && (
        <div>Invalid siteCode</div>
      )}
      {isLoading && <CircularProgress />}
    </Paper>
  );
};
