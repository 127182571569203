import { useCalculatedQuestions } from 'api/saq/hooks/queries';
import {
  QuestionResult,
  QuestionnaireScore,
  ScoreResult,
  SiteCharQuestionScore
} from 'api/saq/types';
import {
  getPillar,
  getAnswerScores,
  createQuestionsByScores
} from 'api/saq/utils';
import { useQuestionScoresData } from '../useQuestionScoresData/useQuestionScoresData';

export const useSiteCharacteristic = (questionnaireType: string) => {
  const {
    scores,
    scoresWithoutQuestions,
    riskQuestions,
    isLoading
  } = useQuestionScoresData('SITE_CHARACTERISTICS', questionnaireType);
  const {
    data: calculatedQuestions,
    isLoading: isCalculatedLoading
  } = useCalculatedQuestions(questionnaireType);
  const knownQuestions = [
    ...(riskQuestions || []),
    ...(calculatedQuestions?.results || [])
  ];

  const saqData = knownQuestions.map((scQuestion: QuestionResult) => {
    const scoreResult = scores?.results.filter(
      (score: ScoreResult) => score.questionCode === scQuestion?.id
    )[0];

    return {
      pillar: getPillar(scQuestion?.tags),
      question: scQuestion?.label,
      questionCode: scoreResult?.questionCode,
      maxScore: scoreResult?.scores.maxScore,
      answerScores: getAnswerScores(scQuestion, scoreResult),
      isSaq: !scoreResult?.questionCode.startsWith('employee.')
    };
  }) as SiteCharQuestionScore[];
  return {
    questions: [
      ...saqData,
      ...createQuestionsByScores(scoresWithoutQuestions)
    ] as SiteCharQuestionScore[],
    scoreResponse: scores,
    isLoading: isLoading || isCalculatedLoading
  } as QuestionnaireScore<SiteCharQuestionScore>;
};
