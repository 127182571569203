import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { useQuestionnaireContext } from '../saq/context/QuestionnaireProvider';

export const QuestionnaireTypeSelector = () => {
  const { questionnaireType, setQuestionnaireType } = useQuestionnaireContext();

  const handleChange = (event: SelectChangeEvent) => {
    setQuestionnaireType(() => event.target.value);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="questionnaire-type">Questionnaire</InputLabel>
        <Select
          labelId="select-questionnaire-type"
          id="select-questionnaire-type"
          value={questionnaireType}
          label="Questionnaire type"
          onChange={handleChange}
        >
          <MenuItem value={'gp'}>Goods Provider</MenuItem>
          <MenuItem value={'sp'}>Service Provider</MenuItem>
          <MenuItem value={'ra'}>Recruitment Agency</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
