import {useQuestionScoresData} from "../useQuestionScoresData/useQuestionScoresData";
import {ManagementCtrlQuestionScore, QuestionResult, QuestionnaireScore, ScoreResult, Section} from "api/saq/types";
import {getAnswerScores} from "api/saq/utils";

export const useManagementControlsRisk = (questionnaireType: string) => {
    const {
        scores,
        subsections,
        riskQuestions,
        isLoading
    } = useQuestionScoresData('MANAGEMENT_CONTROLS', questionnaireType);

    const saqData = (riskQuestions?.map((question: QuestionResult) => {
        const questionScore = scores?.results.filter(
            (score: ScoreResult) => score.questionCode === question.id
        )[0];
        const subsection = subsections.find(
            (subsections: Section) => subsections.id === question?.subsectionId
        );
        return {
            subsectionId: subsection?.id,
            subsectionName: subsection?.name,
            question: question.label,
            questionCode: questionScore?.questionCode,
            maxScore: questionScore?.scores.maxScore,
            answerScores: getAnswerScores(question, questionScore)
        };
    }) || []) as ManagementCtrlQuestionScore[];

    return {
        questions: saqData,
        scoreResponse: scores,
        isLoading
    } as QuestionnaireScore<ManagementCtrlQuestionScore>;
};
