import { TableHead, TableRow } from '@mui/material';
import { TableHeadCell } from '../../screens/saq/SaqRisksTable.styles';

export const ScoresHeader = ({
  title,
  headerNames
}: {
  title: string;
  headerNames: string[];
}) => {
  return (
    <TableHead>
      <TableRow key={`${title} heading`}>
        {headerNames.map((name) => (
          <TableHeadCell key={name}>{name}</TableHeadCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
